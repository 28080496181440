import type { UpdateChecklistItemRequest } from '@witmetrics/api-client';
import { CheckBoxProps } from '@/components/CheckBox';
import { API } from '@/api';

export type CheckBoxChangeEvent = CheckBoxProps<'isComplete'>['onChange'];

export const classes = {
  listItem: 'js-drag-handle cursor-pointer active:cursor-grabbing',
  content:
    'js-draggable grow flex items-start justify-start mx-3 my-2 py-3 pl-4 hover:bg-grey-2 rounded-lg',
  topRow: 'flex justify-between w-full',
  checkboxWrapper: 'pr-2',
  leftContent: 'flex items-center grow',
  rightContent: 'flex items-center',
  description: 'text-grey-8 mr-3 hover:underline cursor-pointer select-none',
  complete: 'text-grey-5 line-through',
  itemEditor: 'w-full',
};

export const styles = {
  listItem: {
    '&': {
      padding: 0,
      alignItems: 'flex-start',
    },
  },
};

export function updateItem(itemID: number, data: UpdateChecklistItemRequest) {
  return API.Checklists.updateChecklistItem(itemID, data);
}
