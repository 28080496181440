import { useAppState } from '@/providers/AppStateProvider';
import Title from '@/components/Title';
import Skeleton from '@mui/material/Skeleton';
import { useUnisonProject } from '@/store/useStore';
import IconButton from '@/components/IconButton';
import OptionsIcon from '@/icons/OptionsIcon';
import CloseIcon from '@/icons/CloseIcon';
import { useRef } from 'react';
import { useToggle } from '@/hooks/useToggle';
import DefaultOptionsMenu from '@/components/DefaultOptionsMenu';
import ProjectDialog from '@/dialogs/ProjectDialog';
import DeleteProjectDialog from '@/dialogs/DeleteProjectDialog';
import ProjectStatus from '@/components/ProjectStatus';
import { classes } from './utils';

export type ProjectHeaderProps = {
  isFetchingData: boolean;
};

export default function ProjectHeader({ isFetchingData }: ProjectHeaderProps) {
  const menuToggleRef = useRef(null);
  const { onClearActiveProject, activeProjectID } = useAppState();
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [isProjectDialogShown, toggleProjectDialog] = useToggle(false);
  const [isDeleteDialogShown, toggleDeleteDialog] = useToggle(false);
  const project = useUnisonProject(activeProjectID);

  const handleEdit = () => {
    toggleMenu();
    toggleProjectDialog(true);
  };

  const handleDelete = () => {
    toggleMenu();
    toggleDeleteDialog(true);
  };

  if (!project && !isFetchingData) return null;

  if (isFetchingData) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.titleWrapper}>
            <Skeleton
              className={classes.skeleton}
              variant="rounded"
              animation="wave"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isProjectDialogShown && (
        <ProjectDialog
          projectID={activeProjectID!}
          onClose={() => toggleProjectDialog(false)}
        />
      )}
      {isDeleteDialogShown && (
        <DeleteProjectDialog
          projectID={activeProjectID!}
          onDelete={() => onClearActiveProject()}
          onClose={() => toggleDeleteDialog(false)}
        />
      )}
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.titleWrapper}>
            <Title size="sm">{project!.name}</Title>
            <ProjectStatus className={classes.status} project={project!} />
          </div>
          <p className={classes.description}>{project!.description}</p>
        </div>
        <div className={classes.iconsWrapper}>
          <IconButton ref={menuToggleRef} onClick={() => toggleMenu()}>
            <OptionsIcon />
          </IconButton>
          <DefaultOptionsMenu
            open={isMenuOpen}
            anchorTo={menuToggleRef.current}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onClose={() => toggleMenu()}
          />
          <IconButton onClick={() => onClearActiveProject()}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
}
