import { PROJECT_STATUSES } from '@/constants/projectStatuses';
import { mapToKey } from '@/utils/arrays';

export const classes = {
  filtersWrapper: 'flex',
  filtersList: 'w-[325px]',
  buttonsWrapper: 'px-4 py-4 flex justify-between items-center',
  resetButton: 'px-3',
  applyButton: 'px-6',
};

export const STATUS_OPTIONS = Object.values(PROJECT_STATUSES).map(
  ({ id, label }) => ({ value: id, label })
);

export const ALL_OPTIONS = mapToKey(STATUS_OPTIONS, 'value');
