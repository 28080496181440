import { useAppState } from '@/providers/AppStateProvider';
import { useDispatch } from '@/store/useStore';
import { addUnisonProject as dispatchAddUnisonProject } from '@/store/slices/unisonProjectsSlice';
import Card from '@/components/Card';
import { useToggle } from '@/hooks/useToggle';
import { useEffect, useState } from 'react';
import ProjectHeader from '@/components/UnisonProjectDetails/ProjectHeader';
import TabsBlock, { Tab } from '@/components/TabsBlock';
import ChecklistsTab from './ChecklistsTab';
import ConversationsTab from '@/components/UnisonProjectDetails/ConversationsTab';
import FilesTab from '@/components/UnisonProjectDetails/FilesTab';
import { usePath } from '@/hooks/usePath';
import {
  classes,
  fetchUnisonProject,
  type ProjectTab,
  TAB_OPTIONS,
  TABS,
} from './utils';

export default function UnisonProjectDetails() {
  const dispatch = useDispatch();
  const { searchParams, pushParams } = usePath();
  const { activeProjectID, onApiError } = useAppState();
  const [defaultTab, setDefaultTab] = useState<ProjectTab>(TABS.CHECKLISTS);
  const [isFetchingData, toggleFetchingData] = useToggle(true);

  useEffect(() => {
    if (activeProjectID) fetchData();
  }, [activeProjectID]);

  useEffect(() => {
    const tab = searchParams.get('projectTab');
    if (tab && Object.values(TABS).includes(tab)) {
      handleSelectTab(tab as ProjectTab);
    }
  }, []);

  const handleSelectTab = (tab: (typeof TAB_OPTIONS)[number]) => {
    setDefaultTab(tab as ProjectTab);
    pushParams({ projectTab: tab });
  };

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const unisonProject = await fetchUnisonProject(activeProjectID!);
      dispatch(dispatchAddUnisonProject(unisonProject));
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching project', () => fetchData());
    }
  };

  if (!activeProjectID) return null;

  return (
    <Card className={classes.card}>
      <ProjectHeader isFetchingData={isFetchingData} />
      <TabsBlock<typeof TAB_OPTIONS>
        className={classes.tabs}
        bodyClassName={classes.tabsBody}
        name="project-tabs"
        defaultTab={defaultTab}
        onChange={handleSelectTab}>
        <Tab className={classes.tab} name="checklists" label="Checklists">
          <ChecklistsTab projectID={activeProjectID} />
        </Tab>
        <Tab className={classes.tab} name="conversations" label="Conversations">
          <ConversationsTab projectID={activeProjectID} />
        </Tab>
        <Tab className={classes.tab} name="files" label="Files">
          <FilesTab projectID={activeProjectID} />
        </Tab>
      </TabsBlock>
    </Card>
  );
}
