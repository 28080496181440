import { useChecklist, useDispatch } from '@/store/useStore';
import {
  deleteChecklist as dispatchDeleteChecklist,
  updateChecklist as dispatchUpdateChecklist,
} from '@/store/slices/checklistsSlice';
import Card from '@/components/Card';
import Checklist from '@/components/Checklist';
import ChecklistCardHeader from '@/components/UnisonProjectDetails/ChecklistsTab/ChecklistCard/ChecklistCardHeader';
import { arrayMove, mapToID } from '@/utils/arrays';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import {
  buildItemsList,
  classes,
  deleteChecklist,
  updateItemOrder,
} from './utils';

export type ChecklistCardProps = {
  isFetchingData?: boolean;
  checklistID: number;
};

export default function ChecklistCard({
  isFetchingData,
  checklistID,
}: ChecklistCardProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [showComplete, toggleShowComplete] = useToggle(true);
  const [isDeleting, toggleDeleting] = useToggle(false);
  const checklist = useChecklist(checklistID);

  const handleReorder = async (removedIndex: number, addedIndex: number) => {
    try {
      if (!checklist?.items) return;
      const items = arrayMove(checklist.items, removedIndex, addedIndex);
      const updatedChecklist = await updateItemOrder(
        checklistID,
        mapToID(items)
      );
      dispatch(dispatchUpdateChecklist(updatedChecklist));
    } catch (err) {
      onApiError(err, 'Error moving item');
    }
  };

  const handleDeleteChecklist = async () => {
    try {
      toggleDeleting(true);
      await deleteChecklist(checklistID);
      dispatch(dispatchDeleteChecklist({ checklistID }));
      toggleDeleting(false);
    } catch (err) {
      toggleDeleting(false);
      onApiError(err, 'Error deleting item');
    }
  };

  return (
    <Card className={classes.card}>
      <ChecklistCardHeader
        showComplete={showComplete}
        checklistID={checklistID}
        onToggleComplete={(update: boolean) => toggleShowComplete(update)}
        onDelete={handleDeleteChecklist}
      />
      <Checklist
        draggable
        isFetchingData={isFetchingData}
        items={buildItemsList(checklist, showComplete)}
        onReorder={handleReorder}
      />
    </Card>
  );
}
