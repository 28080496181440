export const PROJECT_STATUSES = {
  SCAN: { id: 1, label: 'Scan', name: 'SCAN' },
  DESIGN: { id: 2, label: 'Design', name: 'DESIGN' },
  APPROVED: { id: 3, label: 'Approved', name: 'APPROVED' },
  PRINTING: { id: 4, label: 'Printing', name: 'PRINTING' },
  DONE: { id: 5, label: 'Done', name: 'DONE' },
} as const;

export const PROJECT_STATUS_IDS = Object.values(PROJECT_STATUSES).reduce(
  (a, b) => ({ ...a, [b.id]: b }),
  {}
);
