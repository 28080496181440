import { useEffect, useState } from 'react';
import type { DecoratedChecklist } from '@witmetrics/api-client';
import NewChecklist from './NewChecklist';
import ChecklistCard from './ChecklistCard';
import {
  useActiveAccount,
  useChecklistsFromUnisonProjectID,
  useDispatch,
} from '@/store/useStore';
import { setUnisonProjectChecklists as dispatchSetUnisonProjectChecklists } from '@/store/slices/unisonProjectChecklistsSlice';
import { sortByCreatedAt } from '@/utils/arrays';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes, fetchChecklistData } from './utils';

export type ChecklistsTabProps = {
  projectID: number;
};

export default function ChecklistsTab({ projectID }: ChecklistsTabProps) {
  const dispatch = useDispatch();
  const activeAccount = useActiveAccount();
  const { onApiError } = useAppState();
  const checklists = useChecklistsFromUnisonProjectID(projectID);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [checklistTemplates, setChecklistTemplates] = useState<
    DecoratedChecklist[]
  >([]);

  useEffect(() => {
    if (activeAccount) fetchData();
  }, [activeAccount, projectID]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchChecklistData(
        activeAccount!.accountID,
        projectID
      );
      dispatch(dispatchSetUnisonProjectChecklists(data.checklists));
      setChecklistTemplates(data.templates); // Keep them local
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching checklist templates', () => fetchData());
    }
  };

  return (
    <div className={classes.wrapper}>
      {sortByCreatedAt(checklists || []).map((checklist) => (
        <ChecklistCard
          key={checklist.id}
          isFetchingData={isFetchingData}
          checklistID={checklist.id}
        />
      ))}
      <div>
        <NewChecklist
          projectID={projectID}
          checklistTemplates={checklistTemplates}
        />
      </div>
    </div>
  );
}
