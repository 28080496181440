import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useToggle } from '@/hooks/useToggle';
import {
  type ChipTextFieldOption,
  classes,
  type ModifiedAutoCompleteProps,
  type OnRenderInput,
  type OnRenderOption,
  type OnSearchEvent,
  type OnSelectEvent,
  type SelectedOption,
  styles,
} from './utils';

export type { ChipTextFieldOption, SelectedOption };

export interface ChipTextFieldProps<N extends string, T>
  extends ModifiedAutoCompleteProps<T> {
  disabled?: boolean;
  clearOnSelect?: boolean;
  autoFocus?: boolean;
  className?: string;
  wrapperClassName?: string;
  name: N;
  label: string;
  placeholder?: string;
  initialOptions: ChipTextFieldOption<T>[];
  onSearch: (search: string) => Promise<ChipTextFieldOption<T>[]>;
  onSelect: (option: Record<N, SelectedOption<T>>) => void;
}

export default function ChipTextField<
  N extends string,
  T extends string | number,
>({
  disabled = false,
  clearOnSelect = false,
  autoFocus = false,
  className,
  wrapperClassName = '',
  name,
  label,
  placeholder = '',
  initialOptions = [],
  onSelect,
  onSearch,
  ...props
}: ChipTextFieldProps<N, T>) {
  const lastSearch = useRef<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(initialOptions);
  const [isSearching, toggleSearching] = useToggle(false);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  const handleSelect: OnSelectEvent<T> = (e, option) => {
    onSelect({ [name]: option } as Record<N, SelectedOption<T>>);
  };

  const handleSearch: OnSearchEvent<T> = async (e, search, reason) => {
    setInputValue(search);
    if (reason === 'reset' && clearOnSelect) {
      setInputValue('');
      setOptions(initialOptions);
      return null;
    }
    if (search === lastSearch.current) {
      // Prevent running unnecessary searches
      return null;
    }
    lastSearch.current = search;
    toggleSearching(true);
    const newOptions = await onSearch(search);
    setOptions(newOptions);
    toggleSearching(false);
  };

  const renderOption: OnRenderOption<T> = (optionProps, option) => {
    /*
      NOTE: Need to specify key separately to avoid error:
        A props object containing a "key" prop is being spread into JSX
      see https://stackoverflow.com/a/75968316/5839360
     */
    return (
      <ListItem
        {...optionProps}
        key={option.value}
        sx={styles.listItem}
        className={twMerge(optionProps.className, classes.listItem)}>
        <div className={classes.optionWrapper}>
          <label className={classes.label}>{option.label}</label>
          {option.description && (
            <p className={classes.description}>{option.description}</p>
          )}
        </div>
      </ListItem>
    );
  };

  const renderInput: OnRenderInput<T> = (params) => {
    return (
      <TextField
        {...params}
        fullWidth
        variant="standard"
        className={className}
        label={label}
        placeholder={placeholder}
        InputProps={{
          ...params.InputProps,
          autoFocus,
          endAdornment: (
            <>
              {isSearching ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  };

  return (
    <Autocomplete<ChipTextFieldOption<T>, true, false, true, any>
      {...props}
      multiple
      freeSolo
      forcePopupIcon
      disabled={disabled}
      autoComplete
      id={name}
      ListboxProps={{ sx: styles.listbox }}
      className={twMerge(classes.autocomplete, wrapperClassName)}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterOptions={(x) => x}
      options={options}
      value={props.value}
      inputValue={inputValue}
      noOptionsText="No results"
      onChange={handleSelect}
      onInputChange={handleSearch}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );
}
