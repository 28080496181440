'use client';
import { useEffect } from 'react';
import { useToggle } from '@/hooks/useToggle';
import { useActiveAccount, useDispatch } from '@/store/useStore';
import { setUnisonProjects } from '@/store/slices/unisonProjectsSlice';
import { useAppState } from '@/providers/AppStateProvider';
import { usePath } from '@/hooks/usePath';
import { useFilters } from '@/hooks/useFilters';
import { usePagination } from '@/hooks/usePagination';
import { useSorting } from '@/hooks/useSorting';
import type { RequestPagination } from '@witmetrics/api-client';
import type { ProjectFilters } from '@/types/projects';
import UnisonProjectDetails from '@/components/UnisonProjectDetails';
import ProjectsListCard from '@/components/ProjectsListCard';
import {
  classes,
  fetchProjects,
  formatProjectFilters,
  getInitialFilters,
  getInitialProjectID,
  PAGE_SIZE,
} from './utils';

export type ProjectsPageProps = {
  params: {
    projectID: string;
  };
};

export default function ProjectsPage({ params }: ProjectsPageProps) {
  const dispatch = useDispatch();
  const activeAccount = useActiveAccount();
  const { onApiError, onClearActiveProject, onSelectProject, activeProjectID } =
    useAppState();
  const { searchParams, pushParams } = usePath();
  const [filters, setFilters] = useFilters<ProjectFilters[number]>(
    getInitialFilters(searchParams)
  );
  const [pagination, setPagination, { setPage }] = usePagination(1, PAGE_SIZE);
  const [sorting, setSorting, { toggleSortBy }] = useSorting('updatedAt');
  const [isFetchingProjects, toggleFetchingProjects] = useToggle(true);

  useEffect(() => {
    const projectID = getInitialProjectID(params.projectID);
    if (projectID === activeProjectID) return;
    if (projectID) onSelectProject(projectID);
    else onClearActiveProject();
  }, [params]);

  useEffect(() => {
    // Reset & re-fetch if active account, filters, or sorting are changed
    if (!activeAccount?.accountID) return;
    handleFetchProjects({ page: 1, pageSize: PAGE_SIZE });
  }, [filters, sorting, activeAccount]);

  useEffect(() => {
    pushParams({ filters: JSON.stringify(filters) });
  }, [filters]);

  const handleFetchProjects = async ({ page, pageSize }: RequestPagination) => {
    try {
      toggleFetchingProjects(true);
      const projects = await fetchProjects({
        page,
        pageSize,
        filters,
        sorting,
      });
      dispatch(setUnisonProjects(projects.results));
      setPagination(projects.pagination);
      toggleFetchingProjects(false);
    } catch (err) {
      toggleFetchingProjects(false);
      onApiError(err, 'Error fetching projects', () =>
        handleFetchProjects({ page, pageSize })
      );
    }
  };

  const handleFetchMoreProjects = async () => {
    return handleFetchProjects({ ...pagination, page: pagination.page + 1 });
  };

  const toggleSort = (sortBy: string) => {
    setPage(1);
    toggleSortBy(sortBy);
  };

  const handleUpdateFilters = (projectStatusIDs: number[]) => {
    setFilters(formatProjectFilters(projectStatusIDs));
  };

  return (
    <div className={classes.wrapper}>
      {activeProjectID ? (
        <UnisonProjectDetails />
      ) : (
        <ProjectsListCard
          isFetchingData={isFetchingProjects}
          pagination={pagination}
          filters={filters}
          onSort={toggleSort}
          onFilterProjects={handleUpdateFilters}
          onFetchMoreProjects={handleFetchMoreProjects}
        />
      )}
    </div>
  );
}
