import { useEffect, useState } from 'react';
import { useDispatch, useUnisonProject } from '@/store/useStore';
import {
  addUnisonProject as dispatchAddUnisonProject,
  updateUnisonProject as dispatchUpdateUnisonProject,
} from '@/store/slices/unisonProjectsSlice';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import TextField from '@/components/TextField';
import { showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import {
  classes,
  createUnisonProject,
  updateUnisonProject,
  validateFields,
} from './utils';

export type ProjectDialogProps = {
  projectID?: number;
  onClose: () => void;
};

export default function ProjectDialog({
  projectID,
  onClose,
}: ProjectDialogProps) {
  const dispatch = useDispatch();
  const { onSelectProject, onApiError } = useAppState();
  const project = useUnisonProject(projectID);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [isSaving, toggleSaving] = useToggle(false);

  useEffect(() => {
    if (project) fillFields();
  }, [project]);

  const fillFields = () => {
    setName(project!.name);
    setDescription(project!.description);
  };

  const handleSave = async () => {
    try {
      toggleSaving(true);
      const invalidFields = validateFields({ name });
      if (invalidFields.length > 0) return setInvalidFields(invalidFields);
      const data = { name, description };
      if (projectID) {
        const updatedProject = await updateUnisonProject(projectID, data);
        dispatch(dispatchUpdateUnisonProject(updatedProject));
        showSuccessToaster('Project updated');
      } else {
        const newProject = await createUnisonProject(data);
        dispatch(dispatchAddUnisonProject(newProject));
        showSuccessToaster('Project created');
        toggleSaving(false);
        onSelectProject(newProject.id);
      }
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving project', () => handleSave());
    }
  };

  const isSaveDisabled = () => {
    return isSaving || validateFields({ name }).length > 0;
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle
        title={projectID ? 'Edit Project' : 'New Project'}
        onClose={onClose}
      />
      <DialogBody className={classes.body}>
        <TextField
          autoFocus
          error={invalidFields.includes('name')}
          className={classes.name}
          name="name"
          label="Name"
          value={name}
          onChange={(update) => setName(update.name)}
        />
        <TextField
          multiline
          className={classes.description}
          minRows={3}
          maxRows={5}
          name="description"
          label="Description"
          value={description}
          onChange={(update) => setDescription(update.description)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isSaveDisabled()}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
