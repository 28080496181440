import type {
  DecoratedChecklistItem,
  UpdateChecklistItemRequest,
} from '@witmetrics/api-client';
import { twMerge } from 'tailwind-merge';
import ListItem from '@mui/material/ListItem';
import CheckBox from '@/components/CheckBox';
import ItemEditor from '../ItemEditor';
import { Draggable } from 'react-smooth-dnd';
import { updateChecklistItem as dispatchUpdateChecklistItem } from '@/store/slices/checklistItemsSlice';
import { useDispatch } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { type CheckBoxChangeEvent, classes, styles, updateItem } from './utils';

export type ChecklistItemProps = {
  draggable?: boolean;
  item: DecoratedChecklistItem;
};

export default function ChecklistItem({
  draggable = false,
  item,
}: ChecklistItemProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [isEditing, toggleEditing] = useToggle(false);
  const [isUpdating, toggleUpdating] = useToggle(false);

  const handleUpdateItem = async (updates: UpdateChecklistItemRequest) => {
    try {
      toggleUpdating(true);
      const updatedItem = await updateItem(item.id, updates);
      dispatch(dispatchUpdateChecklistItem(updatedItem));
      toggleUpdating(false);
    } catch (err) {
      toggleUpdating(false);
      onApiError(err, 'Error updating checklist item');
    }
  };

  const handleToggleChecked: CheckBoxChangeEvent = ({ isComplete }) => {
    return handleUpdateItem({ isComplete });
  };

  const renderContent = () => {
    return (
      <ListItem sx={styles.listItem} className={classes.listItem}>
        <div className={classes.content}>
          <div className={classes.checkboxWrapper}>
            <CheckBox
              name="isComplete"
              isUpdating={isUpdating}
              isChecked={item.isComplete}
              onChange={handleToggleChecked}
            />
          </div>
          {isEditing ? (
            <ItemEditor
              className={classes.itemEditor}
              itemID={item.id}
              onClose={() => toggleEditing(false)}
            />
          ) : (
            <div
              className={twMerge(
                classes.description,
                item.isComplete && classes.complete
              )}
              onClick={() => toggleEditing(true)}>
              {item.description}
            </div>
          )}
        </div>
      </ListItem>
    );
  };

  return draggable && !isEditing ? (
    /* @ts-ignore -- see https://github.com/kutlugsahin/react-smooth-dnd/issues/93 */
    <Draggable>{renderContent()}</Draggable>
  ) : (
    renderContent()
  );
}
