import type { ReactNode } from 'react';
import Menu, { MenuDivider, type MenuProps } from '@/components/Menu';
import MenuOption from './MenuOption';
import EditIcon from '@/icons/EditIcon';
import DeleteIcon from '@/icons/DeleteIcon';
import { classes } from './utils';

export type DefaultOptionsMenuProps = {
  open: boolean;
  separateDelete?: boolean;
  className?: string;
  anchorTo: HTMLElement | null;
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  extraOptions?: ReactNode;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
};

export default function DefaultOptionsMenu({
  open,
  separateDelete,
  className = '',
  anchorTo,
  anchorOrigin,
  transformOrigin,
  extraOptions,
  onEdit,
  onDelete,
  onClose,
}: DefaultOptionsMenuProps) {
  return (
    <Menu
      open={open}
      className={className}
      anchorTo={anchorTo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}>
      {extraOptions}
      <MenuOption
        label="Edit"
        icon={<EditIcon fontSize="small" />}
        onClick={onEdit}
      />
      {separateDelete && <MenuDivider />}
      <MenuOption
        className={classes.deleteIcon}
        label="Delete"
        // @ts-ignore -- MuiIcon not recognizing custom palette
        icon={<DeleteIcon fontSize="small" color="red-2" />}
        onClick={onDelete}
      />
    </Menu>
  );
}

export { MenuOption, MenuDivider };
