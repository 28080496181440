import { theme } from '@/utils/theme';
import { truncateString } from '@/utils/strings';
import {
  isToday,
  isYesterday,
  renderDateOnlyTime,
  renderTextDate,
} from '@/utils/time';

export const NAME_LENGTH = 80;
export const DESCRIPTION_LENGTH = 250;

export const classes = {
  listItemButton:
    'transition-all duration-100 py-4 px-4 my-1 mx-4 rounded-lg flex flex-col items-start',
  titleRow:
    'flex justify-between items-center w-full text-base text-grey-8 font-medium',
  nameWrapper: 'flex items-center overflow-hidden',
  statusWrapper: 'pr-2 shrink-0',
  name: 'min-w-0 grow',
  nameText: 'text-base',
  date: 'text-sm text-right shrink-0 ml-1',
  description: 'text-base text-grey-7',
};

export const styles = {
  listItemButton: {
    '&': {
      width: 'unset',
      backgroundColor: theme.palette['white'].main,
    },
    '&:hover': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '& .MuiTypography-body1': {
      fontSize: '1rem', // text-base
      lineHeight: '1.5rem', // text-base
      color: theme.palette['grey-8'].main,
    },
    '& .MuiTypography-body2': {
      fontSize: '0.875rem', // text-sm
      lineHeight: '1.25rem', // text-sm
      color: theme.palette['grey-7'].main,
    },
  },
};

function getTruncatedString(str: string, maxLength: number) {
  const label = truncateString(str, maxLength);
  return { label, isTruncated: str.length > label.length };
}

export function getDescriptionString(description: string) {
  return getTruncatedString(description, DESCRIPTION_LENGTH);
}

export function getUpdatedAtString(
  updatedAt: string,
  dateFormat: string | undefined
) {
  if (isToday(updatedAt)) {
    return renderDateOnlyTime(updatedAt);
  } else if (isYesterday(updatedAt)) {
    return `Yesterday, ${renderDateOnlyTime(updatedAt)}`;
  } else if (dateFormat) {
    return renderTextDate(updatedAt, dateFormat);
  } else {
    return renderTextDate(updatedAt);
  }
}
