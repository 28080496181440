import { type CSSProperties, useState } from 'react';
import Title from '@/components/Title';
import Button from '@/components/Button';
import CheckBox from '@mui/material/Checkbox';
import UnderlineTextField from '@/components/UnderlineTextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { mapToKey } from '@/utils/arrays';
import { classes, type FilterOption } from './utils';

export type FiltersListProps<N extends string, T> = {
  className?: string;
  style?: CSSProperties;
  name: N;
  title: string;
  filter?: boolean;
  selected: T[];
  options: FilterOption<T>[];
  onChange: (update: Record<N, T[]>) => void;
};

export default function FiltersList<
  N extends string,
  T extends string | number,
>({
  className = '',
  style = {},
  filter,
  title,
  name,
  options = [],
  selected = [],
  onChange,
}: FiltersListProps<N, T>) {
  const [search, setSearch] = useState('');

  const toggleSelectAll = () => {
    let updates: T[];
    if (selected.length === options.length) updates = [];
    else updates = mapToKey(options, 'value');
    onChange({ [name]: updates } as Record<N, T[]>);
  };

  const handleSelectOption = (option: FilterOption<T>) => {
    let updates;
    if (selected.includes(option.value)) {
      updates = selected.filter((s) => s !== option.value);
    } else {
      updates = [...selected, option.value];
    }
    onChange({ [name]: updates } as Record<N, T[]>);
  };

  const getOptions = () => {
    const query = search.trim().toLowerCase();
    return options.filter((o) => o.label.toLowerCase().includes(query));
  };

  return (
    <div className={className} style={style}>
      <div className={classes.titleWrapper}>
        <Title size="xs">{title}</Title>
        <Button variant="text" onClick={() => toggleSelectAll()}>
          {selected.length === options.length ? 'Clear' : 'Select all'}
        </Button>
      </div>
      {filter && (
        <UnderlineTextField
          showCloseIcon
          className={classes.search}
          placeholder="Filter..."
          value={search}
          onChange={setSearch}
        />
      )}
      <div>
        <List className={classes.list}>
          {getOptions().map((option) => (
            <ListItem
              key={option.value}
              className={classes.listItem}
              onClick={() => handleSelectOption(option)}>
              <CheckBox
                edge="start"
                // @ts-ignore -- MUI not recognizing custom palette
                color="purple-2"
                className={classes.checkbox}
                checked={selected.includes(option.value)}
              />
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}
