import { API } from '@/api';
import { isBlank } from '@/utils/strings';

export const classes = {
  dialog: 'w-[98vw] max-w-[450px] min-w-[350px]',
  body: 'w-full max-h-[80vh] min-h-[100px] pt-2 pb-12 overflow-auto',
  name: 'w-full my-4',
  description: 'w-full mb-8',
};

export function validateFields({ name }: { name: string }) {
  let invalidFields = [];
  if (isBlank(name)) invalidFields.push('name');
  return invalidFields;
}

export function createUnisonProject(data: {
  name: string;
  description: string;
}) {
  return API.UnisonProjects.createNewProject(data);
}

export function updateUnisonProject(
  projectID: number,
  data: { name: string; description: string }
) {
  return API.UnisonProjects.updateUnisonProject(projectID, data);
}
