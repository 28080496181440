import { theme } from '@/utils/theme';
import type { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';

export type SearchableDropdownOption<T> = {
  value: T;
  label: string;
  description?: string;
};

export type BaseAutoCompleteProps<T> = MuiAutocompleteProps<
  SearchableDropdownOption<T>,
  false,
  false,
  true,
  any
>;

export type ModifiedAutoCompleteProps<T> = Omit<
  BaseAutoCompleteProps<T>,
  'onSelect' | 'renderInput' | 'options'
>;

export const classes = {
  autocomplete: 'w-full',
  listItem: 'transition-colors duration-100 mx-2 my-3 rounded-lg',
  optionWrapper: 'flex flex-col font-normal',
  label: 'cursor-pointer text-base text-grey-8',
  description: 'text-sm text-grey-7',
};

export const styles = {
  listbox: {
    '&': {
      padding: 0,
    },
  },
  listItem: {
    '&': {
      width: 'unset',
      backgroundColor: theme.palette['white'].main,
    },
    '&.MuiAutocomplete-option': {
      paddingTop: '0.5rem', // py-2
      paddingBottom: '0.5rem', // py-2
    },
    '&.MuiAutocomplete-option.Mui-focused, & .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused, &.MuiAutocomplete-option[aria-selected="true"].Mui-focused':
      {
        backgroundColor: theme.palette['grey-2'].main,
      },
  },
};
