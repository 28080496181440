'use client';
import type {
  ColumnFilter,
  RequestPagination,
  Sorting,
} from '@witmetrics/api-client';
import type { ProjectFilters } from '@/types/projects';
import { API } from '@/api';
import { buildColumnFilter } from '@/utils/filters';
import { COMPARISON_OPERATORS } from '@/constants/comparisonOperators';
import { isEmpty } from '@/utils/arrays';

export const PAGE_SIZE = 25;

interface FetchProjectParams extends RequestPagination {
  filters: ColumnFilter[];
  sorting: Sorting;
}

export const classes = {
  wrapper: 'w-full h-full flex pl-6 pr-2',
};

export function getInitialFilters(params: URLSearchParams): ProjectFilters {
  return params.get('filters') ? JSON.parse(params.get('filters')!) : [];
}

export function fetchProjects({
  page,
  pageSize,
  filters,
  sorting,
}: FetchProjectParams) {
  return API.UnisonProjects.fetchUnisonProjects({
    pagination: { page, pageSize },
    filters,
    sorting,
  });
}

export function formatProjectFilters(projectStatusIDs: number[]) {
  if (isEmpty(projectStatusIDs)) return [];
  return [
    buildColumnFilter(
      'unisonProjectStatusID',
      COMPARISON_OPERATORS.INCLUDES.value,
      projectStatusIDs
    ),
  ];
}

export function getInitialProjectID(projectID: string) {
  return projectID.toLowerCase() === 'home' ? null : parseInt(projectID);
}
