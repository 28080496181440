import type { ProjectFilters, ProjectStatusFilter } from '@/types/projects';

export const classes = {
  wrapper: 'px-5 pt-6 pb-2 flex items-center justify-start',
  activeBadge: 'mr-4',
  inactiveBadge: 'mr-3',
  iconButton:
    'transition-all cursor-pointer text-grey-6 hover:text-grey-7 ml-3',
  activeFilters: 'active-filters text-purple-2 hover:text-purple-3',
};

export function getStatusIDs(filters: ProjectFilters): number[] {
  const filter = filters.find((f) => f.column === 'unisonProjectStatusID') as
    | ProjectStatusFilter
    | undefined;
  return filter?.value || [];
}
