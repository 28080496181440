import { API } from '@/api';
import { buildColumnFilter } from '@/utils/filters';
import { COMPARISON_OPERATORS } from '@/constants/comparisonOperators';

export const classes = {
  wrapper:
    'flex items-start justify-start overflow-x-auto h-full w-full px-2 py-8',
};

export async function fetchChecklistData(
  practiceID: number,
  projectID: number
) {
  const [templates, checklists] = await Promise.all([
    fetchChecklistTemplates(practiceID),
    fetchProjectChecklists(projectID),
  ]);
  return { templates, checklists };
}

async function fetchChecklistTemplates(practiceID: number) {
  // NOTE: Ignoring pagination for the time being -- unlikely to have more than 100 checklist templates
  const { results } = await API.Practices.fetchPracticeChecklists(practiceID, {
    pagination: { page: 1, pageSize: 100 },
    filters: [
      buildColumnFilter('isTemplate', COMPARISON_OPERATORS.IS.value, true),
    ],
  });
  return results;
}

async function fetchProjectChecklists(projectID: number) {
  const { results } = await API.UnisonProjects.fetchUnisonProjectChecklists(
    projectID,
    {
      pagination: { page: 1, pageSize: 25 },
      filters: [],
    }
  );
  return results;
}
