import { useEffect, useState } from 'react';
import {
  useActiveAccount,
  useConversationsFromUnisonProjectID,
  useDispatch,
} from '@/store/useStore';
import {
  addUnisonProjectConversation as dispatchUnisonProjectConversation,
  setUnisonProjectConversations as dispatchSetUnisonProjectConversations,
} from '@/store/slices/unisonProjectConversationsSlice';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import Conversation from '@/components/Conversation';
import Title from '@/components/Title';
import Button from '@/components/Button';
import ConversationsList from '@/components/ConversationsList';
import NewConversationDialog from '@/dialogs/NewConversationDialog';
import { usePagination } from '@/hooks/usePagination';
import { sortByUpdatedAt } from '@/utils/arrays';
import {
  addProjectConversation,
  classes,
  fetchConversations,
  PAGE_SIZE,
  styles,
} from './utils';

export type ConversationsTabProps = {
  projectID: number;
};

export default function ConversationsTab({ projectID }: ConversationsTabProps) {
  const dispatch = useDispatch();
  const activeAccount = useActiveAccount();
  const { onApiError } = useAppState();
  const conversations = useConversationsFromUnisonProjectID(projectID);
  const [activeConversationID, setActiveConversationID] = useState<
    number | null
  >(null);
  const [pagination, setPagination, { setPage }] = usePagination(1, PAGE_SIZE);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [isNewDialogOpen, toggleNewDialog] = useToggle(false);
  const [isDetailView, toggleDetailView] = useToggle(false);

  useEffect(() => {
    if (activeAccount) fetchData();
  }, [activeAccount, projectID]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      toggleDetailView(false);
      setActiveConversationID(null);
      const data = await fetchConversations(projectID);
      dispatch(dispatchSetUnisonProjectConversations(data.results));
      setPagination(data.pagination);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching conversations', () => fetchData());
    }
  };

  const handleSelectConversation = (conversationID: number) => {
    setActiveConversationID(conversationID);
    toggleDetailView(true);
  };

  const handleCreateConversation = async (conversationID: number) => {
    try {
      const projectConvo = await addProjectConversation(
        projectID,
        conversationID
      );
      dispatch(dispatchUnisonProjectConversation(projectConvo));
      handleSelectConversation(conversationID);
    } catch (err) {
      onApiError(err, 'Error starting conversation');
    }
  };

  return (
    <>
      <div className={classes.wrapper}>
        {isDetailView && activeConversationID ? (
          <Conversation
            conversationID={activeConversationID}
            onClose={() => toggleDetailView(false)}
          />
        ) : (
          <>
            <div className={classes.header}>
              <Title size="sm">Conversations</Title>
              <Button
                className={classes.newButton}
                sx={styles.newButton}
                variant="text"
                // @ts-ignore -- Mui not recognizing custom palette
                color="purple-2"
                onClick={() => toggleNewDialog()}>
                New conversation
              </Button>
            </div>
            <ConversationsList
              isFetchingData={isFetchingData}
              conversations={sortByUpdatedAt(conversations || [])}
              onSelect={handleSelectConversation}
            />
          </>
        )}
      </div>
      {isNewDialogOpen && (
        <NewConversationDialog
          onSave={handleCreateConversation}
          onClose={() => toggleNewDialog(false)}
        />
      )}
    </>
  );
}
