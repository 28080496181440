import { API } from '@/api';

export const classes = {
  card: 'flex flex-col flex-1 my-4 mr-4 max-w-full max-h-full duration-0',
  tabs: 'mt-2 w-full flex flex-col flex-1 min-h-0',
  tabsBody: 'min-h-0 flex flex-1 overflow-x-auto',
  tab: 'flex-1 min-h-0',
};

export async function fetchUnisonProject(projectID: number) {
  return API.UnisonProjects.fetchUnisonProject(projectID);
}

export const TABS = {
  CHECKLISTS: 'checklists',
  CONVERSATIONS: 'conversations',
  FILES: 'files',
} as const;

export const TAB_OPTIONS = Object.values(TABS);

export type ProjectTab = (typeof TABS)[keyof typeof TABS];
