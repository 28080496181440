import { useEffect, useState } from 'react';
import type { UndecoratedUser } from '@witmetrics/api-client';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import {
  useActiveAccount,
  useCurrentUser,
  useDispatch,
} from '@/store/useStore';
import { addConversation as dispatchAddConversation } from '@/store/slices/conversationsSlice';
import { setConversationUsers as dispatchSetConversationUsers } from '@/store/slices/conversationUsersSlice';
import { addConversationMessage as dispatchAddConversationMessage } from '@/store/slices/conversationMessagesSlice';
import TextField from '@/components/TextField';
import ChipTextField, {
  type ChipTextFieldOption,
} from '@/components/ChipTextField';
import {
  buildUserOptions,
  classes,
  createNewConversation,
  fetchPracticeUsers,
  isValid,
  searchUsers,
  sendMessage,
} from './utils';

type NewConversationDialogProps = {
  onSave?: (conversationID: number) => void;
  onClose: () => void;
};

export default function NewConversationDialog({
  onSave,
  onClose,
}: NewConversationDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const currentUser = useCurrentUser();
  const activeAccount = useActiveAccount();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<UndecoratedUser[]>([]);
  const [practiceUsers, setPracticeUsers] = useState<UndecoratedUser[]>([]);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [isSaving, toggleSaving] = useToggle(false);

  useEffect(() => {
    if (activeAccount!.accountID) fetchData();
  }, [activeAccount]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchPracticeUsers(
        activeAccount!.accountID,
        currentUser!.id
      );
      setPracticeUsers(data);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching practice users', () => fetchData());
    }
  };

  const handleSave = async () => {
    try {
      toggleSaving(true);
      const convo = await createNewConversation(
        activeAccount!.accountID,
        subject,
        selectedUsers
      );
      const newMessage = await sendMessage(convo.conversation.id, message);
      dispatch(dispatchAddConversation(convo.conversation));
      dispatch(dispatchSetConversationUsers(convo.conversationUsers));
      dispatch(dispatchAddConversationMessage(newMessage));
      if (onSave) onSave(convo.conversation.id);
      toggleSaving(false);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error starting conversation');
    }
  };

  const handleUsersUpdate = (
    update: Record<'selectedUsers', (string | ChipTextFieldOption<number>)[]>
  ) => {
    // TODO: Why can this possibly be a string?
    const userIDs = update.selectedUsers
      .map((user) => (typeof user === 'string' ? null : user.value))
      .filter((id) => id !== null);
    setSelectedUsers(
      userIDs.map((id) => practiceUsers.find((u) => u.id === id)!)
    );
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="New Conversation" onClose={onClose} />
      <DialogBody className={classes.body}>
        <ChipTextField<'selectedUsers', number>
          autoFocus
          className={classes.membersSearch}
          name="selectedUsers"
          label="Members"
          placeholder="Send to..."
          value={buildUserOptions(selectedUsers)}
          initialOptions={buildUserOptions(practiceUsers)}
          onSearch={(search) => searchUsers(practiceUsers, search)}
          onSelect={handleUsersUpdate}
        />
        <TextField
          className={classes.input}
          name="subject"
          label="Subject (optional)"
          value={subject}
          onChange={(update) => setSubject(update.subject)}
        />
        <TextField
          multiline
          minRows={5}
          maxRows={8}
          className={classes.input}
          name="message"
          label="Message"
          value={message}
          onChange={(update) => setMessage(update.message)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={!isValid(selectedUsers, message)}
        pending={isSaving}
        confirmLabel="Send"
        pendingLabel="Sending..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
