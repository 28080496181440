import { useState } from 'react';
import Menu from '@/components/Menu';
import Divider from '@mui/material/Divider';
import Button from '@/components/Button';
import FiltersList from '@/components/FiltersList';
import { classes, STATUS_OPTIONS } from './utils';

export type ProjectsFilterMenuProps = {
  open: boolean;
  anchorTo: HTMLElement | null;
  statusIDs: number[];
  onApplyFilter: (statusIDs: number[]) => void;
  onClose: () => void;
};

export default function ProjectsFilterMenu({
  open = true,
  anchorTo,
  statusIDs,
  onApplyFilter,
  onClose,
}: ProjectsFilterMenuProps) {
  const [statuses, setStatuses] = useState(statusIDs);

  const handleApply = () => {
    onApplyFilter(statuses);
    onClose();
  };

  const handleReset = () => {
    setStatuses([]);
    onApplyFilter([]);
    onClose();
  };

  return (
    <Menu open={open} anchorTo={anchorTo} onClose={onClose}>
      <div className={classes.filtersWrapper}>
        <FiltersList
          className={classes.filtersList}
          name="projectStatuses"
          title="Project Status"
          selected={statuses}
          options={STATUS_OPTIONS}
          onChange={(update) => setStatuses(update.projectStatuses)}
        />
      </div>
      <Divider />
      <div className={classes.buttonsWrapper}>
        <Button
          variant="text"
          className={classes.resetButton}
          onClick={handleReset}>
          Reset
        </Button>
        <Button className={classes.applyButton} onClick={handleApply}>
          Apply
        </Button>
      </div>
    </Menu>
  );
}
