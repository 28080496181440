import type { DecoratedUnisonProject } from '@witmetrics/api-client';
import type { ProjectFilters, ProjectStatusFilter } from '@/types/projects';
import { isEmpty } from '@/utils/arrays';

export const classes = {
  card: 'flex flex-col w-full overflow-hidden px-0 py-0 m-4',
  tablePagination: 'pt-6',
  content: 'grow min-h-0 pb-12 overflow-y-auto',
};

export function filterProjects(
  projects: DecoratedUnisonProject[] | null,
  filters: ProjectFilters
) {
  if (!projects) return [];
  const filter = filters.find((f) => f.column === 'unisonProjectStatusID') as
    | ProjectStatusFilter
    | undefined;
  if (!filter || isEmpty(filter.value)) return projects;
  return projects.filter((p) => filter.value.includes(p.unisonProjectStatusID));
}
