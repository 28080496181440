import { twMerge } from 'tailwind-merge';
import { useChecklistItem } from '@/store/useStore';
import TextField from '@/components/TextField';
import { useState } from 'react';
import Button from '@/components/Button';
import { useToggle } from '@/hooks/useToggle';
import CheckIcon from '@/icons/CheckIcon';
import { isBlank } from '@/utils/strings';
import { classes } from './utils';

export type ItemEditorProps = {
  itemID: number;
  className?: string;
  onClose: () => void;
};

export default function ItemEditor({
  itemID,
  className = '',
  onClose,
}: ItemEditorProps) {
  const item = useChecklistItem(itemID);
  const [description, setDescription] = useState(item.description);
  const [isSaving, toggleSaving] = useToggle(false);

  const handleSave = () => {
    toggleSaving(true);
    // TODO
    console.log('saving');
    toggleSaving(false);
    onClose();
  };

  return (
    <div className={twMerge(classes.wrapper, className)}>
      <TextField
        autoFocus
        className={classes.textField}
        name="description"
        label=""
        value={description}
        onChange={(update) => setDescription(update.description)}
      />
      <div className={classes.footer}>
        <Button
          variant="text"
          disabled={isSaving}
          className={classes.cancelButton}
          textClasses={classes.buttonText}
          onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          disabled={isSaving || isBlank(description)}
          className={classes.saveButton}
          onClick={handleSave}>
          {isSaving && <CheckIcon className={classes.icon} />}
          {isSaving ? 'Saving..' : 'Save'}
        </Button>
      </div>
    </div>
  );
}
