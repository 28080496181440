import { API } from '@/api';

export const classes = {
  wrapper: 'h-full w-full px-2 py-8',
  header: 'flex items-center justify-start w-full px-2 pb-4',
  title: 'mr-2',
  breadcrumbs: 'py-2',
  emptyWrapper: 'w-full h-full flex flex-col items-center justify-center pt-12',
  emptyTitle: 'text-grey-7 text-xl',
  emptyMessage: 'text-grey-7 text-base pt-4',
  filesWrapper: 'flex flex-wrap gap-6 w-full pt-4 px-2',
  skeleton: 'h-32 w-44 rounded-lg',
};

export const styles = {
  skeleton: (delay: number) => ({
    '&:after': {
      animationDelay: `${delay}s`,
      animationDuration: '1.2s',
    },
  }),
};

export async function fetchProjectData(unisonProjectID: number) {
  // Each project starts with a top-level file collection
  // That's defined by a file_collection w/out a parent_file_collection_id
  const unisonProjectFileCollection =
    await API.UnisonProjects.fetchUnisonProjectFileCollection(unisonProjectID);
  const fileCollectionFiles =
    await API.FileCollections.fetchFileCollectionFiles(
      unisonProjectFileCollection.fileCollectionID,
      {
        filters: [],
        pagination: {
          page: 1,
          pageSize: 100, // NOTE: Should be enough for now
        },
      }
    );
  return {
    unisonProjectFileCollection,
    fileCollectionFiles: fileCollectionFiles.results,
  };
}

export function filterCollectionItems<
  T extends { name: string; description?: string },
>(items: T[] | null, searchQuery: string) {
  const search = searchQuery.toLowerCase().trim();
  if (search.length === 0 || !items) return items;
  return items.filter((item) => {
    return `${item.name} ${item.description || ''}`
      .toLowerCase()
      .includes(search);
  });
}
