import { API } from '@/api';
import { DecoratedChecklist } from '@witmetrics/api-client';

export const classes = {
  card: 'px-0 py-0 flex flex-col h-full w-[400px] overflow-y-auto mr-6',
  list: 'w-full py-0',
};

export function buildItemsList(
  checklist: DecoratedChecklist | null,
  showComplete: boolean
) {
  if (!checklist?.items) return [];
  if (showComplete) return checklist.items;
  return checklist.items.filter((item) => !item.isComplete);
}

export function updateItemOrder(checklistID: number, itemOrder: number[]) {
  return API.Checklists.updateChecklist(checklistID, { itemOrder });
}

export function deleteChecklist(checklistID: number) {
  return API.Checklists.removeChecklist(checklistID);
}
