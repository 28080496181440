import {
  type UndecoratedUser,
  USER_CONVERSATION_STATUS_ID,
} from '@witmetrics/api-client';
import { API } from '@/api';
import { isBlank, renderUserName } from '@/utils/strings';
import { mapToKey } from '@/utils/arrays';

export const classes = {
  dialog: 'w-[98vw] max-w-[700px] min-w-[350px]',
  body: 'w-full max-h-[80vh] min-h-[100px] pt-2 pb-12 overflow-auto',
  membersSearch: 'w-full mb-12',
  input: 'w-full mb-6',
};

export function isValid(users: any[], message: string) {
  return !isBlank(message) && users.length > 0;
}

export async function fetchPracticeUsers(practiceID: number, userID: number) {
  // TODO: Deal with possible pagination
  const { results } = await API.Practices.fetchPracticeUsers(practiceID, {
    filters: [],
    pagination: { page: 1, pageSize: 100 },
  });
  return mapToKey(results, 'user').filter((user) => user.id !== userID);
}

export function buildUserOptions(users: UndecoratedUser[]) {
  return users.map((user) => ({
    value: user.id,
    label: renderUserName(user),
  }));
}

export async function searchUsers(
  users: UndecoratedUser[],
  searchQuery: string
) {
  const search = searchQuery.trim().toLowerCase();
  let suggestions = [...users];
  if (search.length > 0) {
    suggestions = suggestions.filter((u) =>
      renderUserName(u).toLowerCase().includes(search)
    );
  }
  return buildUserOptions(suggestions);
}

export async function createNewConversation(
  practiceID: number,
  subject: string,
  users: UndecoratedUser[]
) {
  return API.Conversations.createConversation({
    subject,
    practiceID,
    users: users.map(({ id }) => ({
      userID: id,
      userConversationStatusID: USER_CONVERSATION_STATUS_ID.MEMBER,
    })),
  });
}

export async function sendMessage(conversationID: number, content: string) {
  return API.Conversations.createConversationMessage(conversationID, {
    content,
    sentAt: new Date().toISOString(),
    fileIDs: [],
    fileCollectionIDs: [],
  });
}
