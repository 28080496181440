import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import Badge from '@mui/material/Badge';
import Title from '@/components/Title';
import FilterIcon from '@/icons/FilterIcon';
import ProjectsFilterMenu from '../ProjectsFilterMenu';
import type { ProjectFilters } from '@/types/projects';
import { useToggle } from '@/hooks/useToggle';
import { classes, getStatusIDs } from './utils';

export type ProjectsListHeaderProps = {
  filters: ProjectFilters;
  onUpdateStatusFilter: (statusIDs: number[]) => void;
};

export default function ProjectsListHeader({
  filters = [],
  onUpdateStatusFilter,
}: ProjectsListHeaderProps) {
  const filterMenuToggleRef = useRef(null);
  const [isFilterMenuOpen, toggleFilterMenu] = useToggle(false);
  const statusIDs = getStatusIDs(filters);
  const isActiveFilter = statusIDs.length > 0 || isFilterMenuOpen;
  return (
    <>
      <div className={classes.wrapper}>
        <Title size="sm">Projects</Title>
        <Badge
          className={
            statusIDs.length > 0 ? classes.activeBadge : classes.inactiveBadge
          }
          // @ts-ignore -- MUI not recognizing custom palette
          color="purple-2"
          badgeContent={statusIDs.length}>
          <FilterIcon
            ref={filterMenuToggleRef}
            className={twMerge(
              classes.iconButton,
              isActiveFilter && classes.activeFilters
            )}
            onClick={() => toggleFilterMenu()}
          />
        </Badge>
      </div>
      {isFilterMenuOpen && (
        <ProjectsFilterMenu
          open
          anchorTo={filterMenuToggleRef.current}
          statusIDs={statusIDs}
          onApplyFilter={onUpdateStatusFilter}
          onClose={() => toggleFilterMenu(false)}
        />
      )}
    </>
  );
}
