import { theme } from '@/utils/theme';
import type {
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteValue,
} from '@mui/material/Autocomplete';

export type SelectedOption<T> = AutocompleteValue<
  ChipTextFieldOption<T>,
  true,
  false,
  true
>;

export type ChipTextFieldOption<T> = {
  value: T;
  label: string;
  description?: string;
};

export type BaseAutoCompleteProps<T> = MuiAutocompleteProps<
  ChipTextFieldOption<T>,
  true,
  false,
  true,
  any
>;

export type ModifiedAutoCompleteProps<T> = Omit<
  BaseAutoCompleteProps<T>,
  'onSelect' | 'renderInput' | 'options'
>;

export type OnSelectEvent<T> = BaseAutoCompleteProps<T>['onChange'];

export type OnSearchEvent<T> = BaseAutoCompleteProps<T>['onInputChange'];

export type OnRenderOption<T> = BaseAutoCompleteProps<T>['renderOption'];

export type OnRenderInput<T> = BaseAutoCompleteProps<T>['renderInput'];

export const classes = {
  autocomplete: 'w-full',
  listItem: 'transition-colors duration-100 mx-2 my-3 rounded-lg',
  optionWrapper: 'flex flex-col font-normal',
  label: 'cursor-pointer text-base text-grey-8',
  description: 'text-sm text-grey-7',
};

export const styles = {
  listbox: {
    '&': {
      padding: 0,
    },
  },
  listItem: {
    '&': {
      width: 'unset',
      backgroundColor: theme.palette['white'].main,
    },
    '&.MuiAutocomplete-option': {
      paddingTop: '0.5rem', // py-2
      paddingBottom: '0.5rem', // py-2
    },
    '&.MuiAutocomplete-option.Mui-focused, & .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused, &.MuiAutocomplete-option[aria-selected="true"].Mui-focused':
      {
        backgroundColor: theme.palette['grey-2'].main,
      },
  },
};
