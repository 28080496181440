import type { RequestPagination } from '@witmetrics/api-client';
import type { ProjectFilters } from '@/types/projects';
import { usePracticeUnisonProjects } from '@/store/useStore';
import Card from '@/components/Card';
import TablePagination from '@/components/TablePagination';
import ProjectsListHeader from './ProjectsListHeader';
import ProjectsList from '@/components/ProjectsList';
import { sortByUpdatedAt } from '@/utils/arrays';
import { classes, filterProjects } from './utils';

export type ProjectsListCardProps = {
  isFetchingData: boolean;
  pagination: RequestPagination;
  filters: ProjectFilters;
  onSort: (sortBy: string) => void;
  onFilterProjects: (statusIDs: number[]) => void;
  onFetchMoreProjects: () => void;
};

export default function ProjectsListCard({
  isFetchingData,
  pagination,
  filters,
  onSort,
  onFilterProjects,
  onFetchMoreProjects,
}: ProjectsListCardProps) {
  // TODO: Sorting
  const projects = usePracticeUnisonProjects();
  const filteredProjects = sortByUpdatedAt(filterProjects(projects, filters));

  return (
    <Card className={classes.card}>
      <ProjectsListHeader
        filters={filters}
        onUpdateStatusFilter={onFilterProjects}
      />
      <div className={classes.content}>
        <ProjectsList
          isFetchingData={isFetchingData}
          projects={filteredProjects}
        />
        <TablePagination
          isFetching={isFetchingData}
          className={classes.tablePagination}
          labels={['project', 'projects']}
          pagination={pagination}
          rowCount={filteredProjects.length}
          onFetch={onFetchMoreProjects}
        />
      </div>
    </Card>
  );
}
