import { API } from '@/api';
import { SORT_ORDER } from '@/constants/sortOrder';

export const classes = {
  wrapper: 'h-full w-full pb-8',
  header: 'flex items-center justify-between w-full px-2 pb-3 pt-5',
  newButton: 'normal-case font-semibold',
  emptyWrapper: 'w-full h-full flex flex-col items-center justify-center pt-12',
  emptyTitle: 'text-grey-7 text-xl',
  emptyMessage: 'text-grey-7 text-base pt-4',
};

export const styles = {
  newButton: {
    '&:focus': {
      border: 'none',
    },
  },
};

export const PAGE_SIZE = 100; // NOTE: Just going big for now to avoid pagination

export function fetchConversations(projectID: number) {
  return API.UnisonProjects.fetchUnisonProjectConversations(projectID, {
    filters: [],
    pagination: { page: 1, pageSize: 50 },
    sorting: { sortBy: 'updatedAt', sortOrder: SORT_ORDER.DESCENDING },
  });
}

export async function addProjectConversation(
  projectID: number,
  conversationID: number
) {
  return API.UnisonProjects.addConversationToUnisonProject(
    projectID,
    conversationID
  );
}
