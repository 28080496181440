import type { DecoratedChecklistItem } from '@witmetrics/api-client';
import { twMerge } from 'tailwind-merge';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ChecklistItem from './ChecklistItem';
import { Container, type ContainerOptions } from 'react-smooth-dnd';
import { classes, styles } from './utils';

export type ChecklistProps = {
  isFetchingData?: boolean;
  draggable?: boolean;
  className?: string;
  items: DecoratedChecklistItem[];
  onReorder: (removedIndex: number, addedIndex: number) => void;
};

export default function Checklist({
  isFetchingData,
  items,
  draggable = false,
  className = '',
  onReorder,
}: ChecklistProps) {
  const handleDrop: ContainerOptions['onDrop'] = ({
    removedIndex,
    addedIndex,
  }) => {
    if (
      removedIndex === addedIndex ||
      removedIndex === null ||
      addedIndex === null
    ) {
      // Didn't move or nothing was added/removed
      return;
    }
    onReorder(removedIndex, addedIndex);
  };

  const renderLoading = () => {
    return [0, 0.2, 0.3].map((delay) => (
      <ListItem key={delay} sx={styles.skeletonItem}>
        <Skeleton
          className={classes.skeleton}
          variant="rectangular"
          animation="wave"
          sx={styles.skeleton(delay)}
        />
      </ListItem>
    ));
  };

  const renderItems = () => {
    return items.map((item) => (
      <ChecklistItem draggable={draggable} key={item.id} item={item} />
    ));
  };

  const renderContent = () => {
    return isFetchingData ? renderLoading() : renderItems();
  };

  return (
    <List sx={styles.list} className={twMerge(classes.list, className)}>
      {draggable ? (
        //@ts-ignore -- see https://github.com/kutlugsahin/react-smooth-dnd/issues/93
        <Container
          dragClass="js-dragging-item"
          dropPlaceholder={{
            className: classes.dropPlaceholder,
          }}
          dragHandleSelector=".js-drag-handle"
          lockAxis="y"
          onDrop={handleDrop}>
          {renderContent()}
        </Container>
      ) : (
        renderContent()
      )}
    </List>
  );
}
