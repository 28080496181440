'use client';
import { type DragEventHandler, useEffect, useRef, useState } from 'react';
import {
  useDispatch,
  useFileCollectionsFromParentID,
  useFilesFromFileCollectionID,
} from '@/store/useStore';
import { addUnisonProjectFileCollection as dispatchAddUnisonProjectFileCollection } from '@/store/slices/unisonProjectFileCollectionsSlice';
import { setFileCollectionFiles as dispatchSetFileCollectionFiles } from '@/store/slices/fileCollectionFilesSlice';
import { setFileCollections as dispatchSetFileCollections } from '@/store/slices/fileCollectionsSlice';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { usePath } from '@/hooks/usePath';
import { fetchCollectionContents } from '@/utils/files';
import FileCollectionCrumbs, {
  type FileCollectionPath,
} from '@/components/FileCollectionCrumbs';
import FileCollection from '@/components/FileCollection';
import FileDropOverlay from '@/components/FileDropOverlay';
import FileCollectionHeader from '@/components/FileCollectionHeader';
import { classes, fetchProjectData, filterCollectionItems } from './utils';

export type FilesTabProps = {
  projectID: number;
};

export default function FilesTab({ projectID }: FilesTabProps) {
  const currentProjectID = useRef(projectID);
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const { pushParams, removeParams } = usePath();
  const [baseCollectionID, setBaseCollectionID] = useState<number>();
  const [activeCollectionID, setActiveCollectionID] = useState<number>();
  const [search, setSearch] = useState('');
  const [crumbs, setCrumbs] = useState<FileCollectionPath[]>([]);
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [isDropOverlayOpen, toggleDropOverlay] = useToggle(false);
  const fileCollections = useFileCollectionsFromParentID(activeCollectionID);
  const files = useFilesFromFileCollectionID(activeCollectionID);

  useEffect(() => {
    if (projectID !== currentProjectID.current) {
      currentProjectID.current = projectID;
      setActiveCollectionID(undefined);
    }
  }, [projectID]);

  useEffect(() => {
    if (activeCollectionID) {
      pushParams({ fileCollectionID: activeCollectionID });
    } else {
      removeParams(['fileCollectionID']);
    }
    fetchData();
  }, [activeCollectionID]);

  const handleFetchProjectFileCollection = async () => {
    try {
      // TODO: pagination -- file & collection ID arrays are all based on Redux state
      toggleFetchingData(true);
      const { unisonProjectFileCollection, fileCollectionFiles } =
        await fetchProjectData(projectID);
      dispatch(
        dispatchAddUnisonProjectFileCollection(unisonProjectFileCollection)
      );
      dispatch(dispatchSetFileCollectionFiles(fileCollectionFiles));
      setActiveCollectionID(unisonProjectFileCollection.fileCollectionID);
      setBaseCollectionID(unisonProjectFileCollection.fileCollectionID);
      setCrumbs([]);
      // NOTE: data fetching will continue since activeCollectionID is being updated
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching file collection', () => fetchData());
    }
  };

  const handleFetchCollectionContents = async () => {
    try {
      // TODO: pagination
      toggleFetchingData(true);
      const data = await fetchCollectionContents(activeCollectionID!, {
        page: 1,
        pageSize: 100,
      });
      dispatch(dispatchSetFileCollections(data.fileCollections.results));
      dispatch(dispatchSetFileCollectionFiles(data.files.results));
      // Exclude the root directory (it's marked by the `Home` crumb)
      setCrumbs(data.path.filter((crumb) => crumb.id !== baseCollectionID));
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching file collection contents', () =>
        fetchData()
      );
    }
  };

  const fetchData = () => {
    return activeCollectionID
      ? handleFetchCollectionContents()
      : handleFetchProjectFileCollection();
  };

  const handleOpenFileCollection = (fileCollectionID: number | undefined) => {
    // TODO: It should never be undefined in this instance
    setActiveCollectionID(fileCollectionID);
    setSearch('');
  };

  const handleDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    if (activeCollectionID) toggleDropOverlay(true);
  };

  return (
    <>
      <div className={classes.wrapper} onDragEnter={handleDragEnter}>
        <FileCollectionHeader
          isFetchingData={isFetchingData}
          fileCollectionID={activeCollectionID}
          search={search}
          onSearch={setSearch}
        />
        <FileCollectionCrumbs
          className={classes.breadcrumbs}
          homeValue={baseCollectionID}
          crumbs={crumbs}
          onSelect={handleOpenFileCollection}
        />
        <FileCollection
          isFetchingData={isFetchingData}
          parentFileCollectionID={activeCollectionID}
          fileCollections={filterCollectionItems(fileCollections, search)}
          files={filterCollectionItems(files, search)}
          onOpenFileCollection={handleOpenFileCollection}
        />
      </div>
      <FileDropOverlay
        open={isDropOverlayOpen}
        parentFileCollectionID={activeCollectionID!}
        onClose={() => toggleDropOverlay(false)}
      />
    </>
  );
}
